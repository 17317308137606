import { TFunction } from "i18next";
import * as yup from "yup";
import dayjs from "dayjs";

const createBeneficiarySchema = (
	t: TFunction,
	options: {
		isEligibility: boolean;
		displayBeneficiaryName: boolean;
		displayBeneficiaryAddress: boolean;
		displayBeneficiaryCMEligible: boolean;
		beneficiaryCINFormat: string;
		minAgeValidation: number;
		displayBeneficiaryDob: boolean;
	},
) => {
	const today = dayjs();
	const minAgeDate = today
		.subtract(options.minAgeValidation || 0, "years")
		.endOf("day")
		.toDate();

	const schema = yup.object().shape({
		firstName: yup.string().when([], () => {
			return options.displayBeneficiaryName
				? yup
						.string()
						.required(t("validations.required_field", { field: "First name" }))
						.min(2, t("validations.min_length", { count: 2 }))
						.max(50, t("validations.max_length", { count: 50 }))
				: yup.string().optional();
		}),
		lastName: yup.string().when([], () => {
			return options.displayBeneficiaryName
				? yup
						.string()
						.required(t("validations.required_field", { field: "Last name" }))
						.min(2, t("validations.min_length", { count: 2 }))
						.max(50, t("validations.max_length", { count: 50 }))
				: yup.string().optional();
		}),
		dateOfBirth: yup.date().when([], () => {
			return options.displayBeneficiaryDob
				? yup
						.date()
						.required(
							t("validations.required_field", { field: "Date of birth" }),
						)
						.max(
							minAgeDate,
							options.minAgeValidation
								? `Beneficiary must be at least ${options.minAgeValidation} years old`
								: "Date of birth cannot be in the future",
						)
				: yup.date().optional();
		}),

		email: yup.string().email(t("validations.invalid_format")),
		phoneNumber: yup
			.string()
			.optional()
			.test("phoneNumber", t("validations.invalid_format"), (value) => {
				if (
					!value ||
					value.trim() === "" ||
					value === "+1" ||
					value === "+1 " ||
					value === "+44" ||
					value === "+44 " ||
					value === "+51" ||
					value === "+51 "
				) {
					return true;
				}

				let phoneRegex;
				if (value.startsWith("+1")) {
					phoneRegex = /^\+1\s?[-. ]?\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}$/;
				} else if (value.startsWith("+44")) {
					phoneRegex = /^\+44\s?[-. ]?\(?\d{4}\)?[-. ]?\d{3}[-. ]?\d{3}$/;
				} else if (value.startsWith("+51")) {
					phoneRegex = /^\+51\s?[-. ]?\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{3}$/;
				} else {
					phoneRegex = /^\+1\s?[-. ]?\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}$/;
				}

				return phoneRegex.test(value);
			}),
		addressLine1: yup.string().when([], () => {
			return options.displayBeneficiaryAddress
				? yup
						.string()
						.test("min", t("validations.min_length", { count: 10 }), (val) => {
							if (!val || val.length === 0) {
								return true;
							}
							return val.length >= 10;
						})
						.test("max", t("validations.max_length", { count: 250 }), (val) => {
							if (!val || val.length === 0) {
								return true;
							}
							return val.length < 250;
						})
				: yup.string().optional();
		}),
		addressLine2: yup.string().when([], () => {
			return options.displayBeneficiaryAddress
				? yup
						.string()
						.test("min", t("validations.min_length", { count: 10 }), (val) => {
							if (!val || val.length === 0) {
								return true;
							}
							return val.length >= 10;
						})
						.test("max", t("validations.max_length", { count: 250 }), (val) => {
							if (!val || val.length === 0) {
								return true;
							}
							return val.length < 250;
						})
				: yup.string().optional();
		}),
		city: yup.string().when([], () => {
			return options.displayBeneficiaryAddress
				? yup
						.string()
						.test("min", t("validations.min_length", { count: 3 }), (val) => {
							if (!val || val.length === 0) {
								return true;
							}
							return val.length >= 3;
						})
						.test("max", t("validations.max_length", { count: 250 }), (val) => {
							if (!val || val.length === 0) {
								return true;
							}
							return val.length < 250;
						})
				: yup.string().optional();
		}),
		state: yup.string().when([], () => {
			return options.displayBeneficiaryAddress
				? yup
						.string()
						.test("min", t("validations.min_length", { count: 5 }), (val) => {
							if (!val || val.length === 0) {
								return true;
							}
							return val.length >= 5;
						})
						.test("max", t("validations.max_length", { count: 250 }), (val) => {
							if (!val || val.length === 0) {
								return true;
							}
							return val.length < 250;
						})
				: yup.string().optional();
		}),
		zipCode: yup.string().when([], () => {
			return options.displayBeneficiaryAddress
				? yup
						.string()
						.test("min", t("validations.min_length", { count: 5 }), (val) => {
							if (!val || val.length === 0) {
								return true;
							}
							return val.length >= 5;
						})
						.test("max", t("validations.max_length", { count: 10 }), (val) => {
							if (!val || val.length === 0) {
								return true;
							}
							return val.length < 10;
						})
				: yup.string().optional();
		}),
		program: yup
			.string()
			.required(t("validations.required_field", { field: "Program" })),
		providerSite: yup
			.string()
			.required(t("validations.required_field", { field: "Provider site" })),
		provider: yup
			.string()
			.required(t("validations.required_field", { field: "Provider" })),
		clientIDNumber: yup
			.string()
			.required(t("validations.required_field", { field: "Client ID Number" }))
			.test("regex", "Invalid format", (value) => {
				if (!value) {
					return true;
				}

				const regex = new RegExp(options.beneficiaryCINFormat);
				return regex.test(value);
			}),
		cmVisitSchedule: yup
			.string()
			.required(
				t("validations.required_field", { field: "CM Visit schedule" }),
			),
		medicaidEligibility: yup.string().when([], () => {
			return options.isEligibility
				? yup.string().required(
						t("validations.required_field", {
							field: "Medicaid eligibility",
						}),
					)
				: yup.string();
		}),
		cmEligibility: yup.boolean().when([], () => {
			return options.displayBeneficiaryCMEligible
				? yup
						.boolean()
						.required(
							t("validations.required_field", { field: "CM Eligibility" }),
						)
				: yup.boolean();
		}),
	});

	return schema;
};

export default createBeneficiarySchema;
